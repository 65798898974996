import { ActionIcon, Badge, Loader, Modal, Select } from "@mantine/core";
import { Divider, message, Tag } from "antd";
import _ from "lodash";
import { useRef, useState } from "react";
import { RequiredIndicator } from "../../../components";
import { useQuery, useMutation } from 'react-query';
import {
    deleteAppointment, getAllNetworkPatients, getAllPatients, getAllStaffs,
    getAppointmentsById, patchCancelAppointment, putAppointment
} from "../../../helpers/api";
import { format, isToday } from "date-fns";
import ConsultationDetail from "./consultation-detail";
import { DatePicker, TimeInput } from "@mantine/dates";
import { IconClock } from "@tabler/icons";
import smalltalk from 'smalltalk';
import NewPatientForm from "../../catalog/components/new-patient";
import { useNavigate } from "react-router-dom";
import { appLinks, appointmentStates } from "../../../helpers/config";
import { getAppointmentStatusColor, openNotification } from "../../../helpers/utilities";


const EditAppointmentComponent = ( { appointmentId, onRefresh, isEditable = true } ) => {

    // states
    const [ state, setState ] = useState( {} );
    const [ toggleLinkToPatient, setLinkPatient ] = useState( false );
    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: "md"
    } );


    // consts
    const navigate = useNavigate();
    const ref = useRef();

    const pickerControl = (
        <ActionIcon variant="subtle" color="gray" onClick={ () => ref.current?.showPicker() }>
            <IconClock style={ { width: 16, height: 16 } } stroke={ 1.5 } />
        </ActionIcon>
    );

    const { isError, isFetching, refetch } = useQuery( {
        queryFn: () => getAppointmentsById( appointmentId ),
        queryKey: [ 'appointment', appointmentId ],
        onSuccess: data => {
            setState( data );
            if ( data?.isCancelled ) isEditable = false;
        }
    } );

    const { data: patients = [], isFetching: fetchingPatients, refetch: fetchPatients } = useQuery( {
        queryFn: getAllNetworkPatients,
        queryKey: [ 'patients' ],
    } );

    const { data: doctors = [], isFetching: fetchingDoctors, refetch: fetchDoctors } = useQuery( {
        queryFn: getAllStaffs,
        queryKey: [ 'doctors' ],
    } );


    const { mutateAsync: updateAppointment, isLoading } = useMutation( ( data ) => putAppointment( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    const { mutateAsync: cancelAppointment, isLoading: isCancelling } = useMutation( ( data ) => patchCancelAppointment( state.id, state.cancelReason ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onRefresh();
                refetch();
                return;
            }
            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );


    //// handlers

    // update
    const handleUpdate = () => {
        //do some validations here
        updateAppointment( state );
    };


    // cancel
    const handleCancel = () => {
        if ( _.isEmpty( state.cancelReason ) ) {
            message.error( 'cancel reason required' );
            return;
        }
        cancelAppointment();
    };


    // start
    const handleStart = () => {

        const patient = patients.find( p => p.id === state?.patientId );

        // check if patient is linked
        if ( !state?.patientId || _.isEmpty( patient ) ) {
            message.error( 'first link patient to appointment' );
            // openNotification( 'Error', 'First link patient to appointment', 'error' );
            return;
        }

        // navigate to the new consultation screen with
        // the state prepopulated with patient information from here
        navigate( appLinks.care.new, {
            state: {
                patient,
                appointmentId: state.id
            }
        } );
    };


    // add patient
    const handleDelete = () => {
        smalltalk.confirm(
            "Delete Appointment", "This action cannot be undone. Continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            deleteAppointment( state.id ).then( res => {
                message.success( 'done!' );
                onRefresh();
                refetch();
            } ).catch( () => message.error( 'error processing request!' ) );
        } ).catch( ex => {
            return false;
        } );
    };


    return (
        <>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size }
            >
                { modal.content }
            </Modal>
            {
                isFetching ?
                    <div className="text-center"><Loader /> please wait...</div>
                    :
                    isError ?
                        <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
                        :
                        !_.isEmpty( state ) ?
                            <>
                                <div>
                                    <Badge
                                        variant="light"
                                        color={ getAppointmentStatusColor( state?.status ) }
                                        className="me-2"
                                    >
                                        { _.capitalize( state?.status ) }
                                    </Badge>
                                    <Tag color={ "teal" }>{ state?.source === 'internal' ? ' RiviaOS' : ' Booking Engine' }</Tag>
                                    <h3 className="mb-0 mt-3">
                                        { _.capitalize( state?.appointmentType ) } Appointment
                                    </h3>
                                    <div className="d-flex">
                                        <h5 className="text-muted">
                                            { isToday( new Date( state?.startDate ) ) ? 'Today ' : new Date( state?.startDate ).toDateString() } @{ format( new Date( state?.startTime ), "h:mm a" ) }
                                        </h5>
                                        {
                                            ( state?.oldDate &&
                                                ( new Date( state?.startDate ).toDateString() != new Date( state?.oldDate ).toDateString() ) ) &&
                                            <s className="text-danger ms-2">
                                                { new Date( state?.oldDate ).toDateString() }
                                            </s>
                                        }
                                        {
                                            ( state?.oldTime &&
                                                ( new Date( state?.startTime ).toTimeString() != new Date( state?.oldTime ).toTimeString() ) ) &&
                                            <s className="text-danger ms-2">
                                                { format( new Date( state?.oldTime ), 'h:mm a' ) }
                                            </s>
                                        }
                                    </div>
                                    <Divider className="mt-1" />
                                    <div className="d-flex">

                                        {/* <label htmlFor="status" >Status: </label> */ }
                                        <Select
                                            placeholder="select status"
                                            allowClear
                                            searchable
                                            id="status"
                                            size="xs"
                                            icon={ <span className="bi bi-info-circle" /> }
                                            className="me-2"
                                            value={ state.status }
                                            onChange={ ( v ) =>
                                                setState( { ...state, status: v } )
                                            }
                                            data={ appointmentStates }
                                        />
                                        {
                                            !state?.patientId &&
                                            <button
                                                onClick={ () => setLinkPatient( true ) }
                                                className="button app-btn is-small fw-bold">
                                                <span className="bi bi-person me-2"></span>
                                                link patient
                                            </button>
                                        }
                                        {/* if update date n time exists, strikethrough the above */ }
                                        {
                                            state?.consultationId &&
                                            <button
                                                onClick={ () => setModal( {
                                                    title: "Consultation",
                                                    isOpen: true,
                                                    size: 'xl',
                                                    content: <ConsultationDetail consultationId={ state.consultationId } />
                                                } ) }
                                                className="button app-btn is-small fw-bold">
                                                <span className="bi bi-eye me-2"></span>
                                                view consultation
                                            </button>
                                        }
                                    </div>

                                    {
                                        toggleLinkToPatient &&
                                        <>
                                            <Divider />
                                            <div className="row">
                                                <div className="field col-11">
                                                    <label className="mb-0" htmlFor="patientId">
                                                        Select Patient to Link
                                                        {/* <RequiredIndicator /> */ }
                                                    </label>
                                                    <Select
                                                        placeholder="select patient"
                                                        allowClear
                                                        searchable
                                                        id="patientId"
                                                        size="md"
                                                        icon={ <span className="bi bi-person" /> }
                                                        className="w-100"
                                                        value={ state.patientId }
                                                        onChange={ ( v ) =>
                                                            setState( { ...state, patientId: v } )
                                                        }
                                                        data={ patients.map( pa => {
                                                            return {
                                                                value: pa.id,
                                                                label: `${ pa.firstName } ${ pa.lastName } (${ pa.contact })`
                                                            };
                                                        } ) }
                                                    />
                                                </div>
                                                <div className="col-1 g-0">
                                                    <button
                                                        onClick={ () => setModal( {
                                                            title: "Add Patient",
                                                            isOpen: true,
                                                            size: 'md',
                                                            content: <NewPatientForm
                                                                showCharm={ false }
                                                                showBottomButtons
                                                                onSuccess={ fetchPatients } />
                                                        } ) }
                                                        className="button mt-4"
                                                    >
                                                        <span className="bi bi-plus-circle" />
                                                        {/* Add */ }
                                                    </button></div>
                                            </div>
                                        </>
                                    }

                                    <Divider />
                                    <div className="row">
                                        <div className="field col-12 col-md-6">
                                            <label className="mb-0" htmlFor="startDate">Date
                                                <RequiredIndicator />
                                            </label>
                                            <DatePicker
                                                onChange={ value =>
                                                    setState( {
                                                        ...state,
                                                        oldDate: state.startDate,
                                                        startDate: new Date( value )
                                                    } )
                                                }
                                                id="startDate"
                                                icon={ <span className="bi bi-calendar" /> }
                                                autoFocus
                                                clearable
                                                size="md"
                                                placeholder="select a date"
                                                disabled={ !isEditable }
                                                value={ new Date( state?.startDate ) }
                                            // format={ dateFormat }
                                            />
                                            <small>scheduled date</small>
                                        </div>
                                        <div className="field col-12 col-md-6">
                                            <label className="mb-0" htmlFor="startTime">Time
                                                <RequiredIndicator />
                                            </label>
                                            {/* <TimePicker
                                                onChange={ data => dropDownSet( "startTime", data, state, setState ) }
                                                id="startTime"
                                                size="large"
                                                disabled={ !isEditable }
                                                allowClear
                                                value={ state.startTime }
                                            /> */}
                                            <TimeInput
                                                rightSection={ pickerControl }
                                                clearable
                                                size="md"
                                                id="startTime"
                                                icon={ <span className="bi bi-clock" /> }
                                                disabled={ !isEditable }
                                                // ref={ ref }
                                                value={ new Date( state?.startTime ) }
                                                onChange={ value =>
                                                    setState( {
                                                        ...state,
                                                        oldTime: state.startTime,
                                                        startTime: new Date( value ),
                                                    } ) }
                                            />
                                            <small>scheduled time</small>
                                        </div>
                                        <div className="field col-12 col-md-6">
                                            <label className="mb-0" htmlFor="appointmentType">
                                                Appointment Type
                                                <RequiredIndicator />
                                            </label>
                                            <Select
                                                placeholder="select type"
                                                allowClear
                                                searchable
                                                id="appointmentType"
                                                icon={ <span className="bi bi-text-wrap" /> }
                                                size="md"
                                                className="w-100"
                                                value={ state.appointmentType }
                                                onChange={ ( v ) =>
                                                    setState( { ...state, appointmentType: v } )
                                                }
                                                data={ [
                                                    {
                                                        value: 'in-person',
                                                        label: 'In Person'
                                                    }, {
                                                        value: 'virtual',
                                                        label: 'Virtual'
                                                    }
                                                ] }
                                            />
                                        </div>
                                        <div className="field col-12 col-md-6">
                                            <label className="mb-0" htmlFor="staffId">
                                                Doctor In-Charge
                                            </label>
                                            <Select
                                                placeholder="select doctor-in-charge"
                                                allowClear
                                                searchable
                                                id="staffId"
                                                icon={ <span className="bi bi-person-circle" /> }
                                                size="md"
                                                className="w-100"
                                                value={ state.staffId }
                                                onChange={ ( v ) =>
                                                    setState( { ...state, staffId: v } )
                                                }
                                                data={
                                                    doctors.map( dc => {
                                                        return {
                                                            value: dc.id,
                                                            label: `${ dc.firstName } ${ dc.lastName }`
                                                        };
                                                    } )
                                                }
                                            />
                                        </div>

                                    </div>
                                    <Divider />
                                    <div className="row">
                                        {
                                            !state?.patientId ?
                                                <>
                                                    <div className="field col-12">
                                                        <label className="mb-0" htmlFor="fullName">Full Name
                                                            <RequiredIndicator />
                                                        </label>
                                                        <input
                                                            className="input" type="text"
                                                            id="fullName"
                                                            placeholder="patient name"
                                                            value={ state.fullName }
                                                            disabled={ !isEditable }
                                                            onChange={ e => setState( { ...state, fullName: e.target.value } ) }
                                                        />
                                                        <small>patient name</small>
                                                    </div>
                                                    <div className="field col-md-6 col-12">
                                                        <label className="mb-0" htmlFor="contact">Contact
                                                            <RequiredIndicator />
                                                        </label>
                                                        <input
                                                            className="input" type="tel"
                                                            id="contact"
                                                            placeholder="contact number"
                                                            disabled={ !isEditable }
                                                            value={ state.contact }
                                                            onChange={ e => setState( { ...state, contact: e.target.value } ) }
                                                        />
                                                        <small>patient contact number</small>
                                                    </div>
                                                    <div className="field col-md-6 col-12">
                                                        <label className="mb-0" htmlFor="email">Email</label>
                                                        <input
                                                            className="input"
                                                            type="email"
                                                            id="email"
                                                            disabled={ !isEditable }
                                                            placeholder="patient email"
                                                            value={ state.email }
                                                            onChange={ e => setState( { ...state, email: e.target.value } ) }
                                                        />
                                                        <small>patient email</small>
                                                    </div>
                                                </> :
                                                <>
                                                    <div className="field col-12">
                                                        <label className="mb-0" htmlFor="patientId">
                                                            Patient
                                                            <RequiredIndicator />
                                                        </label>
                                                        <Select
                                                            placeholder="select patient"
                                                            allowClear
                                                            searchable
                                                            id="patientId"
                                                            size="md"
                                                            className="w-100"
                                                            value={ state.patientId }
                                                            onChange={ ( v ) =>
                                                                setState( { ...state, patientId: v } )
                                                            }
                                                            data={ patients.map( pa => {
                                                                return {
                                                                    value: pa.id,
                                                                    label: `${ pa.firstName } ${ pa.lastName } (${ pa.contact })`
                                                                };
                                                            } ) }
                                                        />
                                                    </div>
                                                </>
                                        }
                                        <div className="field col-12">
                                            <label className="mb-0" htmlFor="complain">Complain
                                                <RequiredIndicator />
                                            </label>
                                            <textarea
                                                className="textarea"
                                                id="complain"
                                                placeholder="complain"
                                                disabled={ !isEditable }
                                                value={ state.complain }
                                                onChange={ e => setState( { ...state, complain: e.target.value } ) }
                                            />
                                            <small>patient complain</small>
                                        </div>
                                    </div>
                                </div>
                                {
                                    state.isCancelled &&
                                    <>
                                        <Divider />
                                        <textarea
                                            className="textarea"
                                            id="cancelReason"
                                            placeholder="cancelReason"
                                            disabled
                                            value={ state.cancelReason }
                                        />
                                    </>
                                }

                                <Divider />
                                <div className="buttons mt-3">
                                    {/* TODO: only doctor can do this */ }
                                    {
                                        ( !state.isComplete && !state.isCancelled ) &&
                                        <button onClick={ handleStart }
                                            className={ `button is-info ${ isLoading && ' is-loading ' }` }>
                                            <span className="bi bi-play-circle me-2"></span>
                                            Start Consultation
                                        </button>
                                    }
                                    <button
                                        onClick={ handleUpdate }
                                        class={ `button is-info ${ !state.isComplete && ' is-light' } ${ isLoading && ' is-loading ' }` }>
                                        <span className="bi bi-arrow-clockwise me-2"></span>
                                        Update
                                    </button>
                                    {/* {
                                        ( !state.isComplete && !state.isCancelled ) &&
                                        <button
                                            onClick={ () => setModal( {
                                                title: 'Cancel Appointment',
                                                isOpen: true,
                                                content:
                                                    <CancelAppointment
                                                        onCancel={ handleCancel }
                                                        reason={ state.cancelReason }
                                                        onUpdate={ value => setState( { ...state, cancelReason: value } ) }
                                                        loading={ isCancelling }
                                                    />
                                            } ) }
                                            class={ `button is-warning is-light ${ isLoading && ' is-loading ' }` }>
                                            <span className="bi bi-x-circle me-2"></span>
                                            Cancel
                                        </button>
                                    } */}
                                    <button
                                        onClick={ handleDelete }
                                        class={ `button is-danger is-light ${ isLoading && ' is-loading ' }` }>
                                        <span className="bi bi-trash me-2"></span>
                                        Delete
                                    </button>
                                </div >
                            </>
                            :
                            <kbd>Appointment not found!</kbd>
            }
        </>
    );
};

export default EditAppointmentComponent;
