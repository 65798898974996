import { Divider } from "antd";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PageTitle, PageHeader } from "../../components";
import SummaryCard, { ConsultationProgressCard } from "../../components/cards";
import { action, logOut, resouces } from "../../helpers/auth";
import { appLinks, constants } from "../../helpers/config";
import AppointmentsComponent from "./components/appointment-components";
import ConsultationsComponent from "./components/consultations-component";
import { ConsultationsChart } from "../../components/charts";
import { Chip, Modal, Paper } from '@mantine/core';
import { getAllAppointments, getAllConsultations, getRevenueAnalytics, putLogOut } from "../../helpers/api";
import _ from 'lodash';
import { addMinutes, format, isToday } from 'date-fns';
import { useQuery } from 'react-query';
import { hasPermission } from "../../helpers/utilities";
import { AttendanceComponent } from "../attendance";
import { IconCalendar, IconCash, IconFirstAidKit, IconPower, IconReplace } from "@tabler/icons";
import NewExpenseForm from "../facility/components/new-expense";
import NewAppointmentForm from "../schedule/components/new-appointment";
import CalendarComponent from '../schedule/components/calendar';
import EditAppointmentComponent from "../schedule/components/edit-appointment";

const HomeIndex = ( props ) => {

  // states
  const [ consultations, setConsultations ] = useState( [] );
  const [ appointments, setAppointments ] = useState( [] );
  const [ views, setViews ] = useState( {
    appointment: 'table',
    chart: 'bar'
  } );

  const [ AppointConsultToggle, setAppointConsultToggle ] = useState(
    hasPermission( resouces.consultations, action.canRead ) ? 'consultations' : ''
  );
  const [ modal, setModal ] = useState( {
    isOpen: false,
    title: '',
    content: null,
    size: 900
  } );

  // consts
  const nav = useNavigate();
  const todaysActivities = ( list ) => {
    const today = list.filter( c => {
      if ( isToday( new Date( c.createdAt ) ) )
        return c;
    } );
    return today;
  };

  // queries
  // const { data: consAnalytics, isFetching: consAnalyticsFetching } = useQuery( {
  //   queryFn: getConsultationAnalytics,
  //   queryKey: [ 'consultation-analytics' ],
  // } );

  // const { data: expensesAnalytics, isFetching: expAnalyticsFetching } = useQuery( {
  //   queryFn: getExpensesAnalytics,
  //   queryKey: [ 'expense-analytics' ],
  //   // enabled: false
  // } );

  const { data: revenueAnalytics, isFetching: revenueAnalyticsFetching } = useQuery( {
    queryFn: getRevenueAnalytics,
    queryKey: [ 'revenue-analytics' ],
  } );

  const { isFetching: consultationFetching } = useQuery( {
    queryFn: getAllConsultations,
    queryKey: [ 'consultations' ],
    onSuccess: data => setConsultations( data )
  } );

  const { isFetching: appointmentsFetching, refetch: fetchAppointments } = useQuery( {
    queryFn: getAllAppointments,
    queryKey: [ 'appointments' ],
    onSuccess: data => setAppointments( data ),
  } );

  return (
    <section>
      <PageHeader title="">
        <Modal
          onClose={ () => setModal( { ...modal, isOpen: false } ) }
          opened={ modal.isOpen }
          title={ modal.title }
          size={ modal.size }
        >
          { modal.content }
        </Modal>
        <div className="d-flex mb-3 mb-md-0">
          <kbd className="app-bg-primary me-5 d-inline-block d-md-none">{ constants.siteTitle }</kbd>
          {/* <span className="bi bi-bell h4 mx-2"></span> */ }
          <NavLink
            end
            to={ appLinks.switchBranch }
            className="d-inline-block d-md-none"
          >
            <IconReplace />
          </NavLink>
          <div className="d-inline-block d-md-none mx-3">
            <AttendanceComponent />
          </div>
          <NavLink
            end
            to={ appLinks.setup.index }
            className="d-inline-block d-md-none mx-2"
          >
            <span className={ `h4 mb-0 me-1 text-secondary bi bi-gear` }></span>
          </NavLink>
          <div onClick={ () => {
            putLogOut();
            logOut();
            window.location.href = appLinks.login;
          } } className="d-inline-block d-md-none mx-2">
            <IconPower color="red" />
          </div>
        </div>
      </PageHeader>
      <PageTitle title="Home" />
      {/* main content row */ }
      <div className="row">
        {/* left side */ }
        <div className="col-md-8 col-12">
          <div className="h-scroll">
            {
              hasPermission( resouces.consultations, action.canCreate )
              &&
              <button
                className="app-btn btn-prim me-3 px-5"
                onClick={ () => nav( appLinks.startConsultation.index ) }
              >
                {/* <span className="bi bi-plus-circle"></span> */ }
                <IconFirstAidKit />
                Consultation
              </button>
            }
            <button
              className="app-btn me-3 px-4"
              onClick={ () => setModal( {
                isOpen: true,
                title: 'Schedule an Appointment',
                size: 'lg',
                content: <NewAppointmentForm onSuccess={ fetchAppointments } />
              } )
              }
            >
              <IconCalendar />
              Schedule
            </button>
            <button
              className="app-btn me-3 px-4"
              onClick={ () => setModal( {
                isOpen: true,
                title: 'Record an expenditure',
                content: <NewExpenseForm showFooter showHeader={ false } onSuccess={ () => { } } />
              } )
              }
            >
              <IconCash />
              Spend
            </button>

            <SummaryCard
              label="consultations today"
              value={ todaysActivities( consultations ).length || 0 }
              className="border"
            />
          </div>
          {/* charts */ }
          <div className="col-12 my-4">
            <Paper className="p-2 border">
              <div className="d-flex justify-content-between mb-2">
                <div className="buttons has-addons text-right">
                  <button
                    onClick={ () => setViews( { ...views, chart: 'bar' } ) }
                    className={ `button is-small ${ views.chart === 'bar' && ' is-info is-dark' }` }>
                    Bars
                  </button>
                  <button
                    onClick={ () => setViews( { ...views, chart: 'line' } ) }
                    className={ `button is-small ${ views.chart === 'line' && ' is-info is-dark' }` }>
                    Lines
                  </button>
                </div>
              </div>
              <ConsultationsChart consultations={ consultations } revenue={ revenueAnalytics } chartType={ views.chart } />
            </Paper>
          </div>
        </div>
        {/* right side */ }
        <div className="col-md-4 col-12">
          <Paper className="p-3 border">
            <div className="d-flex justify-content-between align-items-center">
              <Chip variant="filled"
                checked={ false }
              >{ consultations.filter( c => !c.isComplete ).length }</Chip>
              Pending Consultations
              <button className="app-btn  btn-text"
                onClick={ () => setModal( {
                  isOpen: true,
                  title: 'All Pending Consultations',
                  size: 400,
                  content: <>
                    {
                      consultations
                        .filter( c => !c.isComplete )
                        .map( pr => {
                          return <a key={ pr.id }>
                            <ConsultationProgressCard consultation={ pr } />
                            <Divider />
                          </a>;
                        } )
                    }
                  </>
                } ) }
              >view</button>
            </div>
            <Divider />
            {
              consultations
                .filter( c => !c.isComplete )
                .slice( 0, 3 ) //five
                .map( pr => {
                  return <a key={ pr.id }>
                    <ConsultationProgressCard consultation={ pr } />
                    <Divider />
                  </a>;
                } )
            }
          </Paper>
        </div>
      </div>
      <div className="row d-none d-md-inline">
        {/* toggler */ }
        <div className="col-12 py-2">
          {
            hasPermission( resouces.consultations, action.canRead ) &&
            <div className="buttons has-addons">
              <button className={ `button ${ AppointConsultToggle === 'consultations'
                && 'app-bg-primary text-white' }` }
                onClick={ () => setAppointConsultToggle( 'consultations' ) }>
                Consultations</button>
              <button className={ `button ${ AppointConsultToggle === 'appointments'
                && 'app-bg-primary text-white' }` }
                onClick={ () => setAppointConsultToggle( 'appointments' ) }>
                Appointments</button>
              <button className={ `button ${ AppointConsultToggle === 'referrals'
                && 'app-bg-primary text-white' }` }
                onClick={ () => setAppointConsultToggle( 'referrals' ) }>
                Referrals</button>
            </div>
          }
          <div>
            { ( AppointConsultToggle === "appointments" && hasPermission( resouces.consultations, action.canRead ) )
              &&
              <>
                <div className="d-flex justify-content-between mb-2">
                  <div>.</div>
                  <div className="buttons has-addons text-right">
                    <button
                      onClick={ () => setViews( { ...views, appointment: 'table' } ) }
                      className={ `button is-small ${ views.appointment === 'table' && ' is-info is-dark' }` }>
                      <span className="bi bi-table me-2"></span>
                      Table
                    </button>
                    <button
                      onClick={ () => setViews( { ...views, appointment: 'calendar' } ) }
                      className={ `button is-small ${ views.appointment === 'calendar' && ' is-info is-dark' }` }>
                      <span className="bi bi-calendar me-2"></span>
                      Calendar
                    </button>
                  </div>
                </div>
                {
                  views.appointment === 'table' ?
                    <AppointmentsComponent isLoading={ appointmentsFetching } data={ appointments } refresh={ fetchAppointments } /> :
                    <CalendarComponent
                      events={
                        appointments.map( app => {
                          return {
                            id: app.id,
                            title: `[${ _.capitalize( app?.appointmentType ) }] ${ app?.fullName || `${ app?.patient?.firstName } ${ app?.patient?.lastName }` } - ${ format( new Date( app?.startTime ), "h:mm a" ) }`,
                            start: new Date( app.startTime ),
                            end: addMinutes( new Date( app.startTime ), 30 ),
                          };
                        } )
                      }
                      onSelect={ ( event ) =>
                        setModal( {
                          title: "Appointment",
                          isOpen: true,
                          size: 'lg',
                          content: <EditAppointmentComponent onRefresh={ fetchAppointments } appointmentId={ event.id } />,
                        } )
                      }
                    />
                }
              </>
            }
          </div>
          <div>
            { ( AppointConsultToggle === "consultations" && hasPermission( resouces.consultations, action.canRead ) )
              &&
              <ConsultationsComponent isLoading={ consultationFetching } data={ consultations } />
            }
          </div>
        </div>
      </div>
    </section >
  );
};

export { HomeIndex };
