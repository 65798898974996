import { decryptString, encryptString } from "./utilities";
import { tokenEncryptionKey, userEncryptionKey } from "./config";
import _ from "lodash";


const authMember = ( jwtToken, member ) => {
  if ( !jwtToken || !member ) throw "token not found";

  const eToken = encryptString( jwtToken, tokenEncryptionKey );
  const eMember = encryptString( JSON.stringify( member ), userEncryptionKey );

  sessionStorage.setItem( "mmbr_tkn", eToken );
  sessionStorage.setItem( "mmbr", eMember );
};


const getMemberAuth = () => {
  const token = sessionStorage.getItem( "mmbr_tkn" );
  if ( !token ) return;
  return decryptString( token, tokenEncryptionKey );
};

const getMember = () => {
  const user = sessionStorage.getItem( "mmbr" );
  if ( !user ) return;

  return JSON.parse( decryptString( user, userEncryptionKey ) );
};


const memberLogOut = () => {
  sessionStorage.removeItem( "mmbr_tkn" );
  sessionStorage.removeItem( "mmbr" );
};

const isMemberAuthed = () => {
  const token = sessionStorage.getItem( "mmbr_tkn" );
  return token ? true : false;
};


export { isMemberAuthed, memberLogOut, getMember, getMemberAuth, authMember };
