import { useMutation, useQuery } from 'react-query';
import { getAllFacilitiesBranches, getAllNetworkPatients, getAllPatients, postMemberReferral } from '../../../helpers/api';
import { Loader, Select, Skeleton } from '@mantine/core';
import { RequiredIndicator } from '../../../components';
import { message } from 'antd';
import _ from 'lodash';
import { useAtom } from 'jotai';
import { clearRerralAtom, memberReferralAtom } from '../../../helpers/state';
import { getUser } from '../../../helpers/auth';
import { useEffect } from 'react';
import smalltalk from 'smalltalk';

const ReferMember = ( { destinationId, onSuccess } ) => {
    // atoms
    const [ referralAtom, setReferralAtom ] = useAtom( memberReferralAtom );
    const [ , clearReferral ] = useAtom( clearRerralAtom );


    // queries
    const { data: branches, isFetching: fetchingBranches, refetch: fetchBranches } = useQuery( {
        queryFn: () => getAllFacilitiesBranches(),
        queryKey: [ 'branches' ],
    } );

    const { data: patients, isFetching: fetchingPatients, refetch: fetchPatient } = useQuery( {
        queryFn: () => getAllNetworkPatients(),
        queryKey: [ 'patients' ],
    } );


    const { mutateAsync: createReferral, isLoading } = useMutation( ( data ) => postMemberReferral( referralAtom.patientId, data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onSuccess();
                clearReferral();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error?.response?.data?.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    const handleConfirmReferral = () => {
        smalltalk.confirm(
            "Confirm Referral Request", "Do you want to refer member to another provider?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {
            createReferral( referralAtom );
        } ).catch( ex => {
            message.error( ex.response.data.message );
            return false;
        } );
    };


    useEffect( () => {
        if ( destinationId )
            setReferralAtom( { destinationId } );
    }, [] );


    return (
        <div>
            { fetchingPatients ? <Loader /> :
                <div className="row">
                    {
                        fetchingPatients ?
                            <Skeleton visible={ fetchingPatients } color='blue' /> :
                            <div className='mb-3'>
                                <label htmlFor='patientId'>
                                    Member
                                    <RequiredIndicator />
                                </label>
                                <Select
                                    id='patientId'
                                    value={ referralAtom.patientId }
                                    nothingFound="No match"
                                    onChange={ ( value ) => setReferralAtom( { patientId: value } ) }
                                    size="md"
                                    clearable
                                    autoFocus
                                    searchable
                                    placeholder='Pick a Member'
                                    data={
                                        patients?.map( pat => {
                                            return {
                                                label: `${ pat?.firstName } ${ pat?.lastName }`,
                                                value: pat.id
                                            };
                                        } )
                                    }
                                />
                            </div>
                    }

                    {
                        fetchingBranches ?
                            <Skeleton visible={ fetchingBranches } color='blue' /> :
                            <div className='mb-3'>
                                <label htmlFor='destinationId'>
                                    Destination Provider
                                    <RequiredIndicator />
                                </label>
                                <Select
                                    id='destinationId'
                                    value={ referralAtom.destinationId }
                                    nothingFound="No match"
                                    onChange={ ( value ) => setReferralAtom( { destinationId: value } ) }
                                    size="md"
                                    clearable
                                    searchable
                                    placeholder='Select destination provider'
                                    data={
                                        branches
                                            ?.filter( brh => brh.id !== getUser().branchId )
                                            ?.map( brn => {
                                                return {
                                                    label: `${ brn?.facility?.facilityName } (${ brn.branchName }, ${ brn.location })`,
                                                    value: brn.id
                                                };
                                            } )
                                    }
                                />
                            </div>
                    }

                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="referringDiagnosis">
                            Referring Diagnosis
                            <RequiredIndicator />
                        </label>
                        <textarea
                            className="textarea"
                            required
                            value={ referralAtom.referringDiagnosis }
                            onChange={ e => setReferralAtom( { referringDiagnosis: e.target.value } ) }
                            id="referringDiagnosis"
                            placeholder="referring diagnosis"
                        />
                    </div>
                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="referralReason">
                            Referral Reason
                            <RequiredIndicator />
                        </label>
                        <textarea
                            className="textarea"
                            required
                            value={ referralAtom.referralReason }
                            onChange={ e => setReferralAtom( { referralReason: e.target.value } ) }
                            id="referralReason"
                            placeholder="reason for referral"
                        />
                    </div>
                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="presentingComplain">
                            Presenting Complain
                            <RequiredIndicator />
                        </label>
                        <textarea
                            className="textarea"
                            required
                            value={ referralAtom.presentingComplain }
                            onChange={ e => setReferralAtom( { presentingComplain: e.target.value } ) }
                            id="presentingComplain"
                            placeholder="presenting complain"
                        />
                    </div>
                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="examinationFindings">
                            Examination Findings
                        </label>
                        <textarea
                            className="textarea"
                            value={ referralAtom.examinationFindings }
                            onChange={ e => setReferralAtom( { examinationFindings: e.target.value } ) }
                            id="examinationFindings"
                            placeholder="Examination Findings"
                        />
                    </div>
                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="investigationResults">
                            Investigation Results
                        </label>
                        <textarea
                            className="textarea"
                            value={ referralAtom.investigationResults }
                            onChange={ e => setReferralAtom( { investigationResults: e.target.value } ) }
                            id="investigationResults"
                            placeholder="Investigation results"
                        />
                    </div>
                    <div className="field col-12 mb-3">
                        <label className="mb-0" htmlFor="treatmentGiven">
                            Treatment Given
                        </label>
                        <textarea
                            className="textarea"
                            value={ referralAtom.treatmentGiven }
                            onChange={ e => setReferralAtom( { treatmentGiven: e.target.value } ) }
                            id="treatmentGiven"
                            placeholder="Treatments given to member"
                        />
                    </div>
                </div>
            }
            <div className="row px-2">
                <button
                    onClick={ handleConfirmReferral }
                    className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                    <span className="bi bi-save me-2"></span>
                    Confirm Referral
                </button>
            </div>
        </div>
    );
};

export default ReferMember;;