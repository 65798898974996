import { PageHeader } from "../../components";
import { Divider, Tabs } from "antd";
import { useState } from "react";
import { getAllNetworkPatients, getAllPatients } from "../../helpers/api";
import { useNavigate } from 'react-router-dom';
import { useQuery } from "react-query";
import { hasPermission } from "../../helpers/utilities";
import { action, resouces } from "../../helpers/auth";
import { Menu, Modal } from "@mantine/core";
import { PatientsIndex } from './patients';
import NewPatientForm from './components/new-patient';


const MembersIndex = ( props ) => {
    // states
    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
        size: 500,
        zIndex: 55
    } );

    // consts
    const nav = useNavigate();
    const { TabPane } = Tabs;

    // queries
    const { data: members, isFetching, refetch: fetchMembers } = useQuery( {
        queryFn: getAllNetworkPatients,
        queryKey: [ 'all-members' ],
    } );

    // const { data: networkMembers, isFetching: fetchingNetworkMembers, refetch: fetchNetworkMembers } = useQuery( {
    //     queryFn: getAllNetworkPatients,
    //     queryKey: [ 'network-members' ],
    // } );


    return (
        <section>
            <Modal
                title={ modal.title }
                opened={ modal.open }
                size={ modal.size }
                onClose={ () => setModal( { ...modal, open: false } ) }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            <PageHeader
                title="Members"
                hasBackButton
                description="All network members"
                metaData={ members?.length || 0 }
            >
                <div className="d-flex">
                    <Menu
                        trigger="hover"
                        withArrow
                        transition="scale-y"
                        shadow="md"
                        width={ 150 }
                        className="d-block"
                    >
                        <Menu.Target>
                            {
                                hasPermission( resouces.consultations, action.canCreate ) &&
                                <button className="app-btn btn-prim fw-bold"
                                    onClick={ () => {
                                        setModal( {
                                            content: <NewPatientForm
                                                onSuccess={ fetchMembers }
                                                showCharm={ false }
                                                showBottomButtons
                                            />,
                                            open: true,
                                            title: 'Add Member',
                                            size: 600
                                        } );
                                    } }
                                >
                                    <span className="bi bi-plus-circle me-2"></span>
                                    NEW
                                </button>
                            }
                        </Menu.Target>
                    </Menu>
                </div>
            </PageHeader>
            <Divider className="mt-1" />
            <PatientsIndex onReload={ () => {
                fetchMembers();
                // fetchNetworkMembers();
            } } patients={ members } isFetching={ isFetching } />
            {/* <Tabs defaultActiveKey="1">
                <TabPane tab={
                    <span className="d-flex">
                        <IconCheckbox className="me-2" />
                        Your Members
                        <Badge className="ms-2" color="gray" variant="filled">{ authorizedMembers?.length || 0 }</Badge>
                    </span> } key="1">
                    <PatientsIndex onReload={ () => {
                        fetchMembers();
                        fetchNetworkMembers();
                    } } patients={ authorizedMembers } isFetching={ isFetching } />
                </TabPane>
                <TabPane
                    tab={
                        <span className="d-flex">
                            <IconBuildingCommunity className="me-2" />
                            Network Members
                            <Badge className="ms-2" color="gray" variant="fill">{ networkMembers?.length || 0 }</Badge>
                        </span> } key="3">
                    <NetworkMembers
                        isFetching={ fetchingNetworkMembers }
                        members={ networkMembers }
                        onReload={ () => {
                            fetchNetworkMembers();
                            fetchMembers();
                        } }
                    />
                </TabPane>
            </Tabs> */}
        </section >
    );
};

export { MembersIndex };
