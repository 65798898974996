import { PageHeader } from "../../components";
import { Divider, Tabs } from "antd";
import { useState } from "react";
import { getAllBills, getAllConsultations } from "../../helpers/api";
import { useNavigate } from 'react-router-dom';
import { appLinks } from "../../helpers/config";
import { useQuery } from "react-query";
import { hasPermission } from "../../helpers/utilities";
import { action, resouces } from "../../helpers/auth";
import ExcuseDutySearch from "../../components/shared/excuse-duty-search";
import { Badge, Menu, Modal } from "@mantine/core";
import ConsultationComponent from "./components/consultations";
import BillsComponent from "./components/bills";
import NewBillForm from "../../components/billing/new-bill";
import { IconFirstAidKit, IconReceipt, IconReceipt2, IconReportMedical } from "@tabler/icons";


const CareIndex = ( props ) => {
  // states
  const [ modal, setModal ] = useState( {
    title: "",
    open: false,
    content: "",
    size: 500,
    zIndex: 55
  } );

  // consts
  const nav = useNavigate();
  const { TabPane } = Tabs;

  // queries
  const { data: consultations, isFetching, refetch: fetchConsultations } = useQuery( {
    queryFn: getAllConsultations,
    queryKey: [ 'consultations' ],
  } );

  const { data: bills, isFetching: fetchingBills, refetch: fetchBills } = useQuery( {
    queryFn: getAllBills,
    queryKey: [ 'bills' ],
  } );


  return (
    <section>
      <Modal
        title={ modal.title }
        opened={ modal.open }
        size={ modal.size }
        onClose={ () => setModal( { ...modal, open: false } ) }
        zIndex={ modal.zIndex }
      >
        { modal.content }
      </Modal>
      <PageHeader
        title="Care"
        hasBackButton
        description="member care management"
      >
        <div className="d-flex">
          {
            hasPermission( resouces.consultations, action.canRead ) &&
            <button class="app-btn me-2"
              onClick={ () => setModal( {
                title: 'Find Excuse Duty',
                open: true,
                content: <ExcuseDutySearch />
              } ) }
            >
              <span className="bi bi-search me-2"></span>
              <span className="d-none d-md-inline"> Find Excuse Duty</span>

            </button>
          }
          <Menu
            trigger="hover"
            withArrow
            transition="scale-y"
            shadow="md"
            width={ 150 }
            className="d-block"
          >
            <Menu.Target>
              {
                hasPermission( resouces.consultations, action.canCreate ) &&
                <button className="app-btn btn-prim fw-bold">
                  <span className="bi bi-plus-circle me-2"></span>
                  NEW
                </button>
              }
            </Menu.Target>

            <Menu.Dropdown mr={ 50 }>
              <Menu.Item
                onClick={ () =>
                  hasPermission( resouces.consultations, action.canCreate ) &&
                  nav( appLinks.startConsultation.index )
                }
              >
                <IconReportMedical size={ 20 } color="grey" className="me-2" />
                Consultation
              </Menu.Item>
              <Menu.Item
                onClick={ () => setModal( {
                  title: 'New Bill',
                  content: <NewBillForm onSuccess={ fetchBills } />,
                  open: true,
                  size: 1200,
                  zIndex: 55
                } ) }
              >
                <IconReceipt2 size={ 20 } color="grey" className="me-2" />
                Bill
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </PageHeader>
      <Divider className="mt-1" />
      <Tabs defaultActiveKey="1">
        <TabPane tab={
          <span className="d-flex">
            <IconFirstAidKit className="me-2" />
            Consultations
            <Badge className="ms-2" color="gray" variant="filled">{ consultations?.length || 0 }</Badge>
          </span> } key="1">
          <ConsultationComponent
            consultations={ consultations || [] }
            onReload={ fetchConsultations }
            isloading={ isFetching }
          />
        </TabPane>
        {/* <TabPane tab={ <span><i className="bi bi-lungs-fill me-2" /> Detentions</span> } key="2">
          <kbd>under construction</kbd>
        </TabPane> */}
        <TabPane
          tab={
            <span className="d-flex">
              <IconReceipt className="me-2" />
              Bills
              <Badge className="ms-2" color="gray" variant="fill">{ bills?.length || 0 }</Badge>
            </span> } key="3">
          <BillsComponent bills={ bills } isloading={ fetchingBills } onReload={ fetchBills } />
        </TabPane>
      </Tabs>
    </section >
  );
};

export { CareIndex };
