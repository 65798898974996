import { atom } from 'jotai';

// templates
const carePlanTemplate = {
    assessment: "",
    diagnosis: "",
    plan: "",
    evaluation: "",
    intervention: ""
};
const memberReferralTemplate = {
    id: null,
    referringDiagnosis: "",
    referralReason: "",
    presentingComplain: "",
    examinationFindings: "",
    investigationResults: "",
    treatmentGiven: "",

    patientId: null,
    referringOfficerId: null,
    sourceId: null,
    destinationId: null,
    consultationId: null,
};

const membershipRegistrationTemplate = {
    firstName: "",
    lastName: "",
    gender: "",
    contact: "",
    planSlug: "",
    dob: null
};

// state
const _carePlan = atom( carePlanTemplate );
const _memberReferral = atom( memberReferralTemplate );
// const _otpCode = atom( '' );
const _membershipRegistration = atom( membershipRegistrationTemplate );



// atoms

// CAREPLAN
export const carePlanAtom = atom(
    get => get( _carePlan ),
    ( get, set, plan ) => {
        set( _carePlan, { ...get( _carePlan ), ...plan } );
    }
);

export const clearCarePlanAtom = atom(
    null,
    ( get, set, plan ) => {
        set( _carePlan, carePlanTemplate );
    }
);


// export const otpCodeAtom = atom(
//     get => get( _otpCode ),
//     ( get, set, code ) => {
//         set( _otpCode, code );
//     }
// );


// REFERRAL

export const memberReferralAtom = atom(
    get => get( _memberReferral ),
    ( get, set, referral ) => {
        set( _memberReferral, { ...get( _memberReferral ), ...referral } );
    }
);


export const clearRerralAtom = atom(
    null,
    ( get, set, referral ) => {
        set( _memberReferral, memberReferralTemplate );
    }
);


// MEMBERSHIP REGISTRATION

export const membershipRegistrationAtom = atom(
    get => get( _membershipRegistration ),
    ( get, set, registration ) => {
        set( _membershipRegistration, { ...get( _membershipRegistration ), ...registration } );
    }
);


export const clearRegistrationAtom = atom(
    null,
    ( get, set, registration ) => {
        set( _membershipRegistration, membershipRegistrationTemplate );
    }
);
