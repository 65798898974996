import { Select, Tabs, message } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  // CharmBar,
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllMembershipPlans,
  postNewPatient,
} from "../../../helpers/api";
import { nextOfKinRelationships, Regions } from "../../../helpers/config";
import _ from "lodash";
import { Alert, Stepper, Switch } from '@mantine/core';
import { IconAlertCircle, IconInfoCircle } from '@tabler/icons';
// import { useForm } from "react-hook-form";
// import { dropDownSet } from "../../../helpers/utilities";


// const dropTemplates = {
// insuranceProviderId: null,
// insuranceTypeId: null,
// region: "",
// gender: "",
// };

const relativeTemplate = {
  firstName: '',
  lastName: '',
  primaryContact: '',
  secondaryContact: '',
  relationship: '',
  gender: ''
};

const memberTemplate = {
  firstName: '',
  lastName: '',
  gender: '',
  dob: '',
  contact: '',
  secondaryContact: '',
  email: '',
  location: ''
};

const membershipPlanTemplate = {
  slug: "",
  paymentMethod: "",
  transactionRef: null,
  confirmed: false
};


const NewPatientForm = ( { onSuccess, onError, onExit, showCharm = true, showBottomButtons = false } ) => {

  const [ member, setMember ] = useState( memberTemplate );
  const [ relative, setRelative ] = useState( relativeTemplate );
  const [ membershipPlan, setMembershipPlan ] = useState( membershipPlanTemplate );

  const [ active, setActive ] = useState( 0 );
  const maxSteps = 2;
  const successStep = 3;

  // handlers
  const nextStep = () => setActive( ( current ) => ( current < maxSteps ? current + 1 : current ) );
  const prevStep = () => setActive( ( current ) => ( current > 0 ? current - 1 : current ) );

  const { data: plans, isFetching, refetch } = useQuery( {
    queryFn: getAllMembershipPlans,
    queryKey: [ 'membership-plans' ],
  } );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { mutateAsync: createPatient, isLoading } = useMutation( ( data ) => postNewPatient( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 201 ) {
        message.success( data.data.message );
        onSuccess();
        setMember( memberTemplate );
        setRelative( relativeTemplate );
        setActive( 0 );
        return;
      }
      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error?.response?.data?.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err || 'error processing request!' );
      }
    },
  } );


  return (
    <div className='px-2'>
      <div>
        <IndicatorInfo />
        <Stepper active={ active } onStepClick={ setActive } className='mt-5'>
          <Stepper.Step label="Member" key='step-member' description="bio info">
            <div className="row">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="firstName">
                  First Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  autoFocus
                  value={ member.firstName }
                  onChange={ e => setMember( { ...member, firstName: e.target.value } ) }
                  id="firstName"
                  key='firstName'
                  placeholder="e.g. John"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="lastName">
                  Last Name
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="text"
                  value={ member.lastName }
                  onChange={ e => setMember( { ...member, lastName: e.target.value } ) }
                  id="lastName"
                  key='lastName'
                  placeholder="e.g. Joe"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="gender">
                  Gender
                  <RequiredIndicator />
                </label>
                <Select
                  required
                  allowClear
                  showSearch
                  onChange={ v => setMember( { ...member, gender: v } ) }
                  value={ member.gender }
                  size="large"
                  id="gender"
                  key='gender'
                  className="d-block"
                  placeholder="gender"
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="lastName">
                  Date of Birth
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="date"
                  value={ member.dob }
                  onChange={ e => setMember( { ...member, dob: e.target.value } ) }
                  id="dob"
                  key='dob'
                  placeholder="date of birth"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="contact">
                  Primary Contact
                  <RequiredIndicator />
                </label>
                <input
                  className="input"
                  type="tel"
                  value={ member.contact }
                  onChange={ e => setMember( { ...member, contact: e.target.value } ) }
                  id="contact"
                  key='contact'
                  placeholder="0501234567"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="secondaryContact">
                  Secondary Contact
                </label>
                <input
                  className="input"
                  type="tel"
                  value={ member.secondaryContact }
                  onChange={ e => setMember( { ...member, secondaryContact: e.target.value } ) }
                  id="secondaryContact"
                  key='secondaryContact'
                  placeholder="0508901234"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="email">
                  Email
                </label>
                <input
                  className="input"
                  type="email"
                  value={ member.email }
                  onChange={ e => setMember( { ...member, email: e.target.value } ) }
                  id="email"
                  key='email'
                  placeholder="me@email.com"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="location">
                  Location
                </label>
                <input
                  className="input"
                  type="text"
                  value={ member.location }
                  onChange={ e => setMember( { ...member, location: e.target.value } ) }
                  id="location"
                  key='location'
                  placeholder="e.g. Madina"
                />
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Step onStepClick={ setActive } key="step-relative" label="Next of Kin's" description="details">
            <div className="row mt-3 mb-5">
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="kinFirstName">
                  First Name
                </label>
                <input
                  className="input"
                  type="text"
                  value={ relative.firstName }
                  onChange={ e => setRelative( { ...relative, firstName: e.target.value } ) }
                  id="kinFirstName"
                  key='kinFirstName'
                  placeholder="e.g. Mary"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="kinLastName">
                  Last Name
                </label>
                <input
                  className="input"
                  type="text"
                  id="kinLastName"
                  key='kinLastName'
                  value={ relative.lastName }
                  onChange={ e => setRelative( { ...relative, lastName: e.target.value } ) }
                  placeholder="e.g. Dartey"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="kinGender">
                  Gender
                </label>
                <Select
                  required
                  value={ relative.gender }
                  onChange={ v => setRelative( { ...relative, gender: v } ) }
                  allowClear
                  showSearch
                  size="large"
                  id="kinGender"
                  key='kinGender'
                  className="d-block"
                  placeholder="gender"
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="relationship">
                  Relationship
                </label>
                <Select
                  required
                  allowClear
                  showSearch
                  value={ relative.relationship }
                  onChange={ v => setRelative( { ...relative, relationship: v } ) }
                  size="large"
                  key='relationship'
                  id="relationship"
                  className="d-block"
                  placeholder="gender"
                >
                  { nextOfKinRelationships }
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="kinPrimaryContact">
                  Primary Contact
                </label>
                <input
                  className="input"
                  type="tel"
                  id="kinPrimaryContact"
                  key='kinPrimaryContact'
                  value={ relative.primaryContact }
                  onChange={ e => setRelative( { ...relative, primaryContact: e.target.value } ) }
                  placeholder="0301234567"
                />
              </div>
              <div className="field col-md-6 col-12 mb-5">
                <label className="mb-0" htmlFor="kinSecondaryContact">
                  Secondary Contact
                </label>
                <input
                  className="input"
                  value={ relative.secondaryContact }
                  onChange={ e => setRelative( { ...relative, secondaryContact: e.target.value } ) }
                  type="tel"
                  id="kinSecondaryContact"
                  key='kinSecondaryContact'
                  placeholder="0301234567"
                />
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Step onStepClick={ setActive } label="Plan" key='step-plan' description="subscription">

            <Alert className='m-4' icon={ <IconAlertCircle /> } title="Membership Plan" color='cyan' >
              Members can optionally subscribe to a membership plan for added benefits within the network. This can be changed later
            </Alert>

            {/* 
            <div className="field px-4">
              <label className="mb-0" htmlFor="membershipPlans">
                Membership Plan
              </label>
              <Select
                required
                value={ membershipPlan.slug }
                onChange={ v => setMembershipPlan( { ...membershipPlan, slug: v } ) }
                allowClear
                showSearch
                size="large"
                id="membershipPlans"
                className="d-block"
                placeholder="membership plan"
              >
                {
                  plans?.map( plan => {
                    return <Option value={ plan.slug }>{ plan.planName } (GHS { parseFloat( plan.price ) })</Option>;
                  } )
                }
              </Select>
              <div className="mt-3">
                <p className='mb-0'>How is payment collected?</p>
                <div className="buttons has-addons">
                  <button
                    className={ `button ${ membershipPlan.paymentMethod === 'cash' && ' is-warning is-active' }` }
                    onClick={ e => {
                      e.preventDefault(); setMembershipPlan( { ...membershipPlan, paymentMethod: 'cash' } );
                    } }>Cash</button>
                  <button
                    className={ `button ${ membershipPlan.paymentMethod === 'electronic' && ' is-warning is-active' }` }
                    onClick={ e => {
                      e.preventDefault(); setMembershipPlan( { ...membershipPlan, paymentMethod: 'electronic' } );
                    } }>Electronic</button>
                </div>
              </div> 
              {
                membershipPlan.paymentMethod === 'cash' &&
                <Switch
                  id="autoGenerateFolderNumbers"
                  onLabel="YES"
                  offLabel="NO"
                  label='I confirm receipt of membership payment'
                  className='mt-3'
                />
              }
            </div> 
            */}

            {/* {
              membershipPlan.slug &&
              <>
                <Alert className='m-4' icon={ <IconInfoCircle /> } title={ `About '${ plans?.find( plan => plan.slug === membershipPlan.slug )?.planName }'` } color='gray' >
                  { plans?.find( plan => plan.slug === membershipPlan.slug )?.description }
                </Alert>
              </>
            } */}

          </Stepper.Step>
        </Stepper>
      </div>
      <div className="text-center my-4">
        <button onClick={ prevStep } className='button me-2'>
          <span className="bi bi-arrow-left me-2" />
          Back
        </button>
        {
          active === 1 &&
          <button onClick={ nextStep } className='button is-info is-light me-2'>
            Skip
            <span className="bi bi-chevron-double-right ms-2" />
          </button>
        }
        {
          active !== maxSteps &&
          <button onClick={ nextStep } className='button'>
            Next
            <span className="bi bi-arrow-right ms-2" />
          </button>
        }
        {/* {
          membershipPlan.paymentMethod === 'electronic' &&
          <button onClick={ nextStep } className='button is-warning is-dark me-2'>
            <span className="bi bi-cash me-2" />
            Pay
          </button>
        } */}
        {
          active === maxSteps &&
          <button onClick={ () => createPatient( { ...member, relations: [ relative ] } ) } className={ `button is-info ${ isLoading && ' is-loading' }` }>
            <span className="bi bi-check-all me-2" />
            Submit
          </button>
        }
      </div>
    </div >
  );
};

export default NewPatientForm;


{/* <div className="row px-2">
                <Divider />
                <button
                  type="submit" id="submit" className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading ' }` }>
                  <span className="bi bi-save me-2"></span>
                  Save
                </button>
              </div> */}


{/* <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="nhisNumber">
                  NHIS Number
                </label>
                <input
                  className="input"
                  type="text"
                  {...register("nhisNumber")}
                  id="nhisNumber"
                  placeholder="Health Insurance number"
                />
              </div> 
             <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="insuranceProviderId">
                  Insurance Provider
                </label>
                <Select
                  name="insuranceProviderId"
                  allowClear
                  showSearch
                  value={ dropValue.insuranceProviderId }
                  onChange={ ( v ) =>
                    dropDownSet(
                      "insuranceProviderId",
                      v,
                      dropValue,
                      setDropValues
                    )
                  }
                  size="large"
                  id="insuranceProviderId"
                  className="d-block"
                  placeholder="select insurance provider"
                >
                  { insuranceProviders && insuranceProviders.map( ( ip ) => (
                    <Option value={ ip.id } key={ ip.id }>
                      { ip.providerName }
                    </Option>
                  ) ) }
                </Select>
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="ghCardNumber">
                  Insurance Number
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "insuranceNumber" ) }
                  id="insuranceNumber"
                  placeholder="patient's insurance number"
                />
              </div> */}
{/* <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="gpsAddress">
                  GPS Address
                </label>
                <input
                  className="input"
                  type="text"
                  { ...register( "gpsAddress" ) }
                  id="gpsAddress"
                  placeholder="patient's GPS address"
                />
              </div>
              <div className="field col-md-6 col-12">
                <label className="mb-0" htmlFor="region">
                  Region
                </label>
                <Select
                  value={ dropValue.region }
                  onChange={ ( v ) =>
                    dropDownSet( "region", v, dropValue, setDropValues )
                  }
                  name="region"
                  showSearch
                  allowClear
                  size="large"
                  id="region"
                  className="d-block"
                  placeholder="region"
                >
                  { Regions }
                </Select>
              </div> */}