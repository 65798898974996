import { Select, Tabs, message } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  CharmBar,
  IndicatorInfo,
  RequiredIndicator,
} from "../../../components";
import {
  getAllInsuranceProviders,
  getPatient,
  putUpdatePatient,
} from "../../../helpers/api";
import { nextOfKinRelationships, Regions } from "../../../helpers/config";
import _ from "lodash";
import { Alert, Loader } from "@mantine/core";
import { IconAlertCircle } from '@tabler/icons';


const EditPatientForm = ( { patientId, onSuccess, onExit, showCharm = true, isEditable = true } ) => {

  const [ patient, setPatient ] = useState( {} );
  const [ relative, setRelative ] = useState( {} );

  const { Option } = Select;
  const { TabPane } = Tabs;

  const { isError, isFetching } = useQuery( {
    queryFn: () => getPatient( patientId ),
    queryKey: [ 'patient', patientId ],
    onSuccess: data => {

      setPatient( _.omit( data, "avatarUrl", "lastConsultationDate", "relations" ) );
      setRelative( data.relations[ 0 ] );
    }
  } );

  // const { data: insuranceProviders = [] } = useQuery( {
  //   queryFn: getAllInsuranceProviders,
  //   queryKey: [ 'insurances' ],
  // } );

  const updateData = ( fieldName, data ) => {
    setPatient( { ...patient, [ fieldName ]: data } );
  };

  const { mutateAsync: updatePatient, isLoading } = useMutation( ( data ) => putUpdatePatient( data ), {
    onSuccess: ( data, variables, context ) => {
      if ( data.status === 200 ) {
        message.success( data.data.message );
        onSuccess();
        return;
      }

      throw data;
    },
    onError: ( error, variables, context ) => {
      const err = error.response.data.message;
      if ( _.isArray( err ) ) {
        err.map( err =>
          message.error( err.message )
        );
      }
      else {
        message.error( err );
      }
    },
  } );

  // const handleCreate = async ( data ) => {
  //   updatePatient( patient );
  // };


  return (
    <div>
      {
        isFetching ?
          <div className="text-center"><Loader /> please wait...</div>
          :
          isError ?
            <kbd className="bg-danger p-2">Sorry, we encountered an error processing your request</kbd>
            :
            <div>
              { showCharm && (
                <CharmBar>
                  <button className={ `button app-btn btn-prim mx-2 ${ isLoading && ' is-loading' }` } type="submit">
                    Save
                  </button>
                  <button className="button app-btn" onClick={ onExit }>
                    Cancel
                  </button>
                </CharmBar>
              ) }

              <IndicatorInfo />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Member" key="1">
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="firstName">
                        First Name
                        <RequiredIndicator />
                      </label>
                      <input
                        disabled={ !isEditable }
                        value={ patient.firstName }
                        onChange={ e => updateData( 'firstName', e.target.value ) }
                        className="input"
                        type="text"
                        autoFocus
                        id="firstName"
                        placeholder="e.g.Abena"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Last Name
                        <RequiredIndicator />
                      </label>
                      <input
                        disabled={ !isEditable }
                        value={ patient.lastName }
                        onChange={ e => updateData( 'lastName', e.target.value ) }
                        className="input"
                        type="text"
                        id="lastName"
                        placeholder="e.g. Mary"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="gender">
                        Gender
                        <RequiredIndicator />
                      </label>
                      <Select
                        required
                        disabled={ !isEditable }
                        value={ patient.gender }
                        onChange={ v => updateData( 'gender', v ) }
                        size="large"
                        id="gender"
                        className="d-block"
                        placeholder="gender"
                      >
                        <Option value="Male" >Male</Option>
                        <Option value="Female" >Female</Option>
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Date of Birth
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        value={ patient.dob }
                        onChange={ e => updateData( 'dob', e.target.value ) }
                        disabled={ !isEditable }
                        required
                        type="date"
                        id="dob"
                        placeholder="date of birth"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="contact">
                        Primary Contact
                        <RequiredIndicator />
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.contact }
                        onChange={ e => updateData( 'contact', e.target.value ) }
                        type="tel"
                        id="contact"
                        placeholder="0501234567"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="secondaryContact">
                        Secondary Contact
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.secondaryContact }
                        onChange={ e => updateData( 'secondaryContact', e.target.value ) }
                        type="tel"
                        id="secondaryContact"
                        placeholder="0201234567"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.email }
                        onChange={ e => updateData( 'email', e.target.value ) }
                        type="email"
                        id="email"
                        placeholder="john@email.com"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="location">
                        Location
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.location }
                        onChange={ e => updateData( 'location', e.target.value ) }
                        type="location"
                        id="location"
                        placeholder="e.g. Adenta"
                      />
                    </div>
                  </div>
                </TabPane>

                <TabPane tab="Relative" key="2">
                  <div className="row">
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="kinFirstName">
                        First Name
                      </label>
                      <input
                        className="input"
                        type="text"
                        autoFocus
                        value={ relative?.firstName }
                        onChange={ e => setRelative( { ...relative, firstName: e.target.value } ) }
                        id="kinFirstName"
                        placeholder="e.g. Mary"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        className="input"
                        type="text"
                        id="lastName"
                        value={ relative?.lastName }
                        onChange={ e => setRelative( { ...relative, lastName: e.target.value } ) }
                        placeholder="e.g. Dartey"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="kinGender">
                        Gender
                      </label>
                      <Select
                        required
                        value={ relative?.gender }
                        onChange={ v => setRelative( { ...relative, gender: v } ) }
                        allowClear
                        showSearch
                        size="large"
                        id="kinGender"
                        className="d-block"
                        placeholder="gender"
                      >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="relationship">
                        Relationship
                      </label>
                      <Select
                        required
                        allowClear
                        showSearch
                        value={ relative?.relationship }
                        onChange={ v => setRelative( { ...relative, relationship: v } ) }
                        size="large"
                        id="relationship"
                        className="d-block"
                        placeholder="gender"
                      >
                        { nextOfKinRelationships }
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="kinPrimaryContact">
                        Primary Contact
                      </label>
                      <input
                        className="input"
                        type="tel"
                        id="kinPrimaryContact"
                        value={ relative?.primaryContact }
                        onChange={ e => setRelative( { ...relative, primaryContact: e.target.value } ) }
                        placeholder="0301234567"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="kinSecondaryContact">
                        Secondary Contact
                      </label>
                      <input
                        className="input"
                        value={ relative?.secondaryContact }
                        onChange={ e => setRelative( { ...relative, secondaryContact: e.target.value } ) }
                        type="tel"
                        id="kinSecondaryContact"
                        placeholder="0301234567"
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Plan" key="3">

                  <Alert
                    className='m-4'
                    icon={ <IconAlertCircle /> }
                    color='blue' title="Member's care plan" >
                    Each member's care plan will be managed here.
                  </Alert>

                </TabPane>
              </Tabs>
              {
                isEditable &&
                <button
                  onClick={ () => updatePatient( { ...patient, relations: [ relative ] } ) }
                  type="submit" id="submit" className={ `button app-btn btn-prim h6 mt-3 ${ isLoading && ' is-loading ' }` }>
                  <span className="bi bi-check-all me-2"></span>
                  Update Record
                </button>
              }
            </div>
      }

    </div>
  );
};

export { EditPatientForm };


{/* <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="insuranceProviderId">
                        Insurance Provider
                      </label>
                      <Select
                        name="insuranceProviderId"
                        value={ patient.insuranceProviderId }
                        disabled={ !isEditable }
                        allowClear
                        showSearch
                        onChange={ v => updateData( 'insuranceProviderId', v ) }
                        size="large"
                        id="insuranceProviderId"
                        className="d-block"
                        placeholder="select insurance provider"
                      >
                        { insuranceProviders?.map( ( ip ) => (
                          <Option value={ ip.id } key={ ip.id } selected={ ip.id === patient.insuranceProviderId }>
                            { ip.providerName }
                          </Option>
                        ) ) }
                      </Select>
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="ghCardNumber">
                        Insurance Number
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.insuranceNumber }
                        onChange={ e => updateData( 'insuranceNumber', e.target.value ) }
                        type="text"
                        id="insuranceNumber"
                        placeholder="member's insurance number"
                      />
                    </div> */}


{/* <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="gpsAddress">
                        GPS Address
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.gpsAddress }
                        onChange={ e => updateData( 'gpsAddress', e.target.value ) }
                        type="text"
                        id="gpsAddress"
                        placeholder="member's GPS address"
                      />
                    </div>
                    <div className="field col-md-6 col-12">
                      <label className="mb-0" htmlFor="region">
                        Region
                      </label>
                      <Select
                        value={ patient.region }
                        disabled={ !isEditable }
                        // required
                        onChange={ v => updateData( 'region', v ) }
                        name="region"
                        size="large"
                        id="region"
                        className="d-block"
                        placeholder="region"
                      >
                        { Regions }
                      </Select>
                    </div>

                    <div className="field col-12">
                      <label className="mb-0" htmlFor="location">
                        Location
                      </label>
                      <input
                        className="input"
                        disabled={ !isEditable }
                        value={ patient.location }
                        onChange={ e => updateData( 'location', e.target.value ) }
                        type="text"
                        id="location"
                        placeholder="member's location"
                      />
                    </div> */}