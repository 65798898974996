import { useState } from 'react';
import { useQuery } from 'react-query';
import { getFacilityServices } from '../../../helpers/api';
import { Loader } from '@mantine/core';
import { Divider } from 'antd';

const ProviderServices = ( { providerId } ) => {
    const [ filteredData, setFilteredData ] = useState( [] );

    // queries
    const { data: services, isFetching, refetch: fetchServices } = useQuery( {
        queryFn: () => getFacilityServices( providerId ),
        queryKey: [ 'provider-services' ],
        onSuccess: data => setFilteredData( data )
    } );


    return (
        <div>
            <div className='row'>
                <div className="col-md-6 col-12">
                    <input
                        type="text"
                        className="input"
                        placeholder='search services here'
                        onChange={ ( e ) =>
                            setFilteredData(
                                services.filter(
                                    ( sv ) =>
                                        sv.serviceName
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() ) ||
                                        sv.serviceCode
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() ) ||
                                        sv.baseRate
                                            .toString()
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() ) ||
                                        sv.category?.title
                                            .toLowerCase()
                                            .includes( e.target.value.toLowerCase() )
                                )
                            )
                        }
                    />
                </div>
            </div>
            <Divider />
            { isFetching && <Loader /> }
            <table className="table table-bordered table-striped">
                <thead>
                    <th>#</th>
                    <th>Code</th>
                    <th>Service Name</th>
                    <th>Price</th>
                    <th>Cetagory</th>
                    {/* <th>Active</th> */ }
                </thead>
                <tbody>
                    {
                        filteredData?.map( ( sv, index ) =>
                            <tr>
                                <td>{ ++index }</td>
                                <td>{ sv.serviceCode } </td>
                                <td>{ sv.serviceName }</td>
                                <td>{ parseFloat( sv.baseRate ) }</td>
                                <td>{ sv.category?.title }</td>
                                {/* <td>{ sv.isActive && <span className='bi bi-check-all' /> }</td> */ }
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div >
    );
};

export default ProviderServices;;