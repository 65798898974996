import {
  TextInput,
  Checkbox,
  Paper,
  Text,
  Container,
  Modal,
} from "@mantine/core";
import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyRightTag, PageTitle } from "../../components";
import { postGenerateOTP, postMemberLogin } from "../../helpers/api";
import { termsandConditions } from "../../helpers/config";
import { authMember } from "../../helpers/memberAuth";
import logo from '../../static/img/riviaos-logo.png';

export function MemberLoginPage () {

  const [ login, setLogin ] = useState( {
    memberId: "",
    otp: "",
    tnc: false,
    otpGenerated: false
  } );
  const [ busy, setBusy ] = useState( false );
  const [ modelOpen, setOpenModal ] = useState( false );

  const validate = () => {
    if ( !login.memberId ) {
      message.info( "input your Membership ID" );
      return;
    }

    if ( !login.tnc ) {
      message.error( "You must accept the terms and conditions" );
      return;
    }

  };

  const handleGenerateOTP = ( e ) => {
    e.preventDefault();
    validate();

    setBusy( true );
    postGenerateOTP( login.memberId )
      .then( ( res ) => {

        if ( res.status === 201 )
          setLogin( { ...login, otpGenerated: true } );

      } )
      .catch( ( ex ) => {
        message.error( ex.response.data.message );
      } )
      .finally( () => {
        setBusy( false );
      } );
  };

  const handleLogin = ( e ) => {
    e.preventDefault();
    validate();

    setBusy( true );
    postMemberLogin( login )
      .then( ( res ) => {

        if ( res.status === 201 ) {
          authMember( res.data.token, res.data.data );
          // setBusy( true );
          window.location.reload();
        }

      } )
      .catch( ( ex ) => {
        message.error( ex.response.data.message );
        setBusy( false );
      } );
  };

  return (
    <Container size={ 420 } my={ 20 }>
      {/* <PageTitle title="Member Login" /> */ }
      <div className="text-center">
        <img src={ logo } alt="riviaos logo" width={ 200 } />
      </div>
      <Text color="dimmed" size="sm" align="center" mt={ 5 }>
        Login to continue
      </Text>

      <Paper withBorder shadow="md" p={ 30 } mt={ 20 } radius="md">
        <form onSubmit={ handleLogin } method="POST">
          {
            !login.otpGenerated ?
              <>
                <TextInput
                  autoFocus
                  onChange={ ( e ) => setLogin( { ...login, memberId: e.target.value } ) }
                  value={ login.memberId }
                  label="Member ID"
                  size="md"
                  placeholder="Membership ID here"
                  required
                />

                <button
                  onClick={ handleGenerateOTP }
                  className={ `button mt-3 h6 app-btn btn-prim py-3 px-2 w-100  ${ busy && "is-loading"
                    }` }
                >
                  Confirm
                </button>
              </> : <>
                <TextInput
                  autoFocus
                  onChange={ ( e ) => setLogin( { ...login, otp: e.target.value } ) }
                  value={ login.otp }
                  label="Confirm OTP Code"
                  size="md"
                  placeholder="Enter the OTP code sent!"
                  required
                />

                <button
                  onClick={ handleLogin }
                  className={ `button mt-3 h6 app-btn btn-prim py-3 px-2 w-100  ${ busy && "is-loading"
                    }` }
                >
                  Login
                </button>
              </>
          }
        </form>
        <Checkbox
          value={ login.tnc }
          onChange={ () => setLogin( { ...login, tnc: !login.tnc } ) }
          className="mt-2" label={ <>
            I Accept the
            <a className="pt-2" onClick={ () => setOpenModal( true ) }> terms and conditions</a>
          </> } />
        {/* <a className="pt-2" onClick={ () => setOpenModal( true ) }>Terms and conditions</a> */ }
      </Paper>
      <div className="text-center">
        <CopyRightTag />
      </div>

      <Modal
        title={ <strong>Rivia Terms and Conditions</strong> }
        opened={ modelOpen }
        onClose={ () => setOpenModal( false ) }>
        <p>
          { termsandConditions }
        </p>
      </Modal>
    </Container>
  );
}
