import { Badge, Chip } from '@mantine/core';
import { PageTitle } from './page-title';
import { useNavigate, Link } from 'react-router-dom';

const PageHeader = ( { title, description, hasBackButton, children, metaData } ) => {
    const navigate = useNavigate();

    return (
        <div className='d-flex justify-content-between'>
            <PageTitle title={ title } />

            <div className='d-flex justify-content-between'>
                <h4 className='d-flex align-items-center app-color'>
                    {
                        hasBackButton && <>
                            <Link to="#" onClick={ () => navigate( -1 ) }>
                                <span className='bi bi-arrow-left-short h4 me-2'></span>
                            </Link>
                        </>
                    }
                    { title }
                    { metaData && <Badge className=" ms-2" color="gray" variant="filled">{ metaData }</Badge> }
                    {/* // <Chip disabled className="ms-2" title={ metaData } /> } */ }
                </h4>
                <span className='mt-1 ms-3 d-md-inline-block d-none text-muted'>{ description }</span>
            </div>
            <div>
                { children }
            </div>
        </div>
    );
};

export { PageHeader };