import { Divider } from "antd";
import { PageHeader, ProviderCard } from "../../components";
import { useQuery } from 'react-query';
import { getAllFacilities } from '../../helpers/api';
import { Badge, Loader, Modal } from '@mantine/core';
import { useState } from 'react';
import ProviderServices from './components/services';
import ReferMember from './components/refer';

const NetworkIndex = ( props ) => {
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ modal, setModal ] = useState( {
        title: "",
        open: false,
        content: "",
        size: 500,
        zIndex: 55
    } );

    // queries
    const { data: providers, isFetching, refetch: fetchProviders } = useQuery( {
        queryFn: getAllFacilities,
        queryKey: [ 'facilities' ],
        onSuccess: data => setFilteredData( data )
    } );


    return (
        <section>
            <Modal
                title={ modal.title }
                opened={ modal.open }
                size={ modal.size }
                onClose={ () => setModal( { ...modal, open: false } ) }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            <PageHeader title="Network Directory" description="Access all network providers" hasBackButton />
            <Divider className="mt-2" />
            <input
                className="input w-25 mb-5"
                onChange={ ( e ) =>
                    setFilteredData(
                        providers.filter(
                            ( p ) =>
                                p.facilityName
                                    .toLowerCase()
                                    .includes( e.target.value.toLowerCase() )
                        )
                    )
                }
                title="search here"
                placeholder="search the network here"
                style={ { minWidth: "30vw" } }
            />
            <div>
                { isFetching && <Loader /> }
            </div>
            <div className="row px-3">
                {
                    filteredData?.map( provider =>
                        <ProviderCard
                            providerName={ provider?.facilityName }
                            providerType={ provider?.type }
                            outletsCount={ provider?.branches.length }
                            location={ provider?.branches?.find( br => br.isMain )?.location }
                            contact={ provider?.contact }
                            className="col-3 me-2 mb-2"
                            onReferMember={ () => {
                                setModal( {
                                    content: <ReferMember
                                        destinationId={ provider.id }
                                        onSuccess={ () => { } }
                                    />,
                                    open: true,
                                    title: 'Refer a Member',
                                    size: 500,
                                    zIndex: 10
                                } );
                            } }
                            onViewService={ () => {
                                setModal( {
                                    content: <ProviderServices providerId={ provider.id } />,
                                    open: true,
                                    title: `${ provider.facilityName } Services`,
                                    size: 900
                                } );
                            } }
                        />
                    )
                }
            </div>
        </section>
    );
};

export { NetworkIndex };