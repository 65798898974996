import logo from '../../static/img/riviaos-logo.png';
import { CopyRightTag, MembershipPlanCard, PageTitle, RequiredIndicator } from "../../components";
import { useMutation, useQuery } from 'react-query';
import { getAllPublicMembershipPlans, postMembershipSelfRegistration } from '../../helpers/api';
import { message } from 'antd';
import _ from 'lodash';
import { Alert, Loader, Select, Stepper } from '@mantine/core';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { clearRegistrationAtom, membershipRegistrationAtom } from '../../helpers/state';
import { appLinks } from '../../helpers/config';
import { Link } from 'react-router-dom';


const PublicMembershipRegistration = ( props ) => {

    // states
    const [ registrationAtom, setRegistrationAtom ] = useAtom( membershipRegistrationAtom );
    const [ , clearRegistration ] = useAtom( clearRegistrationAtom );
    const [ active, setActive ] = useState( 0 );

    const maxSteps = 1;
    const successStep = 2;

    // handlers
    const nextStep = () => setActive( ( current ) => ( current < maxSteps ? current + 1 : current ) );
    const prevStep = () => setActive( ( current ) => ( current > 0 ? current - 1 : current ) );

    const { data: plans, isFetching, refetch } = useQuery( {
        queryFn: getAllPublicMembershipPlans,
        queryKey: [ 'membership-plans' ],
    } );


    const { mutateAsync: registerWOWMember, isLoading } = useMutation( ( data ) => postMembershipSelfRegistration( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                setActive( successStep );
                clearRegistration();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error?.response?.data?.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    const handleJoinWOW = () => registerWOWMember( registrationAtom );


    return (
        <div>
            <PageTitle title="Rivia Membership Registration" />
            {/* <LoadingOverlay visible={ isLoading } title='Mixing WOW ingredients!' /> */ }
            <div className="text-center my-3">
                <img src={ logo } alt="riviaos logo" width={ 150 } />
            </div>

            <div className="row text-center">
                <div className="col-10 mx-auto">
                    <p className="display-4 mb-0">Hello <strong className='app-color'>WOW</strong> Seeker!</p>
                    <p>To start Experiencing the WOW, provide your details below</p>
                </div>
            </div>

            <div className="my-3">
                <div className="col-md-3 col-10 field mx-auto">
                    <Stepper active={ active } onStepClick={ setActive }>
                        <Stepper.Step label="You" description="your personal info">
                            <div className="my-3">
                                <label className="mb-0" htmlFor="firstName">
                                    First Name
                                    <RequiredIndicator />
                                </label>
                                <input
                                    className="input"
                                    type="text"
                                    autoFocus
                                    id="firstName"
                                    placeholder="member's first name"
                                    value={ registrationAtom.firstName }
                                    onChange={ e => setRegistrationAtom( { firstName: e.target.value } ) }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-0" htmlFor="lastName">
                                    Last Name
                                    <RequiredIndicator />
                                </label>
                                <input
                                    className="input"
                                    type="text"
                                    id="lastName"
                                    placeholder="member's last name"
                                    value={ registrationAtom.lastName }
                                    onChange={ e => setRegistrationAtom( { lastName: e.target.value } ) }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-0" htmlFor="contact">
                                    Phone Number (without country code)
                                    <RequiredIndicator />
                                </label>
                                <input
                                    className="input"
                                    type="tel"
                                    id="contact"
                                    placeholder="e.g. 0501234567"
                                    value={ registrationAtom.contact }
                                    onChange={ e => setRegistrationAtom( { contact: e.target.value } ) }
                                />
                                <small><kbd>note:</kbd> this will become your Membership ID</small>
                            </div>
                            <div className="mb-3">
                                <label className="mb-0" htmlFor="gender">
                                    Gender
                                    <RequiredIndicator />
                                </label>
                                <Select
                                    id='gender'
                                    nothingFound="No match"
                                    value={ registrationAtom.gender }
                                    onChange={ ( value ) => setRegistrationAtom( { gender: value } ) }
                                    size="md"
                                    clearable
                                    searchable
                                    placeholder="what's your gender?"
                                    data={ [ {
                                        label: "Male",
                                        value: "Male"
                                    }, {
                                        label: "Female",
                                        value: "Female"
                                    } ] }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="mb-0" htmlFor="dob">
                                    Date of Birth
                                    <RequiredIndicator />
                                </label>
                                <input
                                    className="input"
                                    type="date"
                                    id="dob"
                                    placeholder="date of birth"
                                    value={ registrationAtom.dob }
                                    onChange={ e => setRegistrationAtom( { dob: e.target.value } ) }
                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step onStepClick={ setActive } label="Plan" description="membership plan">
                            { isFetching && <Loader /> }
                            {
                                plans?.map( plan =>
                                    <MembershipPlanCard
                                        planName={ plan.planName }
                                        description={ plan.description }
                                        price={ plan.price }
                                        isSelected={ plan.slug === registrationAtom.planSlug }
                                        className='mb-2'
                                        onSelect={ () => setRegistrationAtom( { planSlug: plan.slug } ) }
                                    // onViewDetails={}
                                    />
                                    //     <div>
                                    //     <p>  { plan?.planName }</p>
                                    //     <p>  { plan?.description }</p>
                                    //     <p>  { plan?.price }</p>
                                    // </div> 
                                )
                            }

                        </Stepper.Step>
                        <Stepper.Completed>
                            <p className='display-4 fw-bold app-color mt-5'>🎉 Nice!</p>
                            <Alert color="teal">
                                <p className='display-6'>Welcome aboard, <strong>{ registrationAtom.firstName }!</strong> </p>
                                <p> Get started by visiting our clinics or virtual care points to experience the care you deserve! <br />
                                </p>
                                <p><strong><Link to={ appLinks.healthPassport }>Log into RiviaOS </Link></strong> to access your health profile</p>
                            </Alert>
                        </Stepper.Completed>
                    </Stepper>

                    <div className="text-center mt-4">

                        {
                            active < successStep &&
                            <button
                                onClick={ prevStep }
                                className={ `button app-btn h6 me-3` }>
                                <span className="bi bi-arrow-left me-2"></span>
                                Back
                            </button>
                        }

                        {
                            active === successStep &&
                            <>
                                <button
                                    onClick={ () => {
                                        // clearRegistration();
                                        setActive( 0 );
                                    } }
                                    className={ `button app-btn h6 me-3` }>
                                    Register Another!
                                </button>
                                <Link
                                    to={ appLinks.healthPassport }
                                    className={ `button app-btn btn-prim h6 me-3` }>
                                    Log into RiviaOS
                                </Link>
                            </>
                        }
                        {
                            active < maxSteps &&
                            <button
                                onClick={ nextStep }
                                className={ `button app-btn h6` }>
                                Next
                                <span className="bi bi-arrow-right ms-2"></span>
                            </button>
                        }
                        {
                            ( ( successStep - active ) === 1 ) &&
                            <button
                                onClick={ handleJoinWOW }
                                className={ `button h6 app-btn btn-prim ${ isLoading && ' is-loading ' }` }>
                                <span className="bi bi-check-all me-2"></span>
                                Confirm Membership
                            </button>
                        }

                    </div>
                </div>
            </div>

            <div className="text-center">
                <CopyRightTag />
            </div>

        </div >
    );
};

export { PublicMembershipRegistration };






